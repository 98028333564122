<template>
  <div class="sub-table">
    <b-table-simple responsive fixed>
      <thead>
        <tr>
          <th width="10%"><Ellipsis>Situação</Ellipsis></th>
          <th><Ellipsis>Accession Number</Ellipsis></th>
          <th><Ellipsis>Exame/Procedimento</Ellipsis></th>
          <th class="border-right"></th>
        </tr>
      </thead>  

      <template v-if="groupedItems">
        <tbody>
          <template v-for="groupKey in Object.keys(groupedItems)">
            <tr
              v-for="(item, itemIdx) in groupedItems[groupKey]"
              :key="item.id"
              :class="[
                groupKey !== 'pending' &&
                itemIdx + 1 === groupedItems[groupKey].length
                  ? 'boundary'
                  : 'attendance'
              ]"
            >
              <td class="td-body">

                <template>
                  <b-button
                    class="option pending"
                    v-if="!hasLabpacsItemUrl(item)"
                    pill
                    disabled
                  >
                    <p><strong>Pendente</strong></p>
                  </b-button>
                  <b-button
                    class="option finished"
                    v-else-if="hasLabpacsItemUrl(item)"
                    pill
                    disabled
                  >
                    <p><strong>Realizado</strong></p>
                  </b-button>
                </template>
              </td>
              <td class="td-body">
                <span>
                  <Ellipsis>{{item?.labpacs_item?.accession_number}}</Ellipsis>
                </span>
              </td>
              <td class="td-body">
                <template v-if="item.item.clinic_procedure">
                  <ProcedureLine
                    ellipsis
                    :procedure="item.item.clinic_procedure"
                    hideType
                  />
                </template>
                <template v-else>
                  <ProcedureLine ellipsis :procedure="item.item" hideType />
                </template>
              </td>
              
              <td class="tr-body">
                <div class="d-flex flex-row justify-content-end">

                  <ChatIcon 
                    class="icon mr-2 button"
                    v-b-tooltip.hover.v-light.bottom title="Anotação" 
                    @click="openAnnotationModal(item)"
                    v-if="hasLabpacsItem(item)"
                  />

                  <PlusBallonIcon 
                    class="icon mr-2 laudo-icon"
                    v-b-tooltip.hover.v-light.bottom title="Solicitar laudo"
                    @click="handleRequestReport(item)"
                    v-if="hasLabpacsItemUrl(item) && !hasReport(item)"
                  />

                  <PlusBallonIcon 
                    class="icon mr-2 laudo-icon"
                    style="cursor: default !important;"
                    v-b-tooltip.hover.v-light.bottom title="Laudo Solicitado"
                    v-if="hasLabpacsItemUrl(item) && hasRequestedReport(item)"
                  />

                  <PrinterIcon 
                    class="icon mr-2 printer-icon"
                    v-b-tooltip.hover.v-light.bottom title="Baixar e imprimir laudo" 
                    @click="handlePrint(item)" 
                    v-if="hasLabpacsItemUrl(item) && hasApprovedReport(item)"
                  />

                  <PlusBallonGreenIcon 
                    class="icon mr-2 laudo-icon"
                    style="cursor: default !important;"
                    v-b-tooltip.hover.v-light.bottom title="Exame Laudado"
                    v-if="hasLabpacsItemUrl(item) && hasApprovedReport(item)"
                  />

                  <ExportIcon 
                    class="icon mr-2 button"
                    v-b-tooltip.hover.v-light.bottom title="Exportar para worklist"
                    @click="sendToWorklist(item)"
                    v-if="!hasLabpacsItem(item)"
                  />

                  <FileIcon
                    class="icon button"
                    v-b-tooltip.hover.v-light.bottom title="Visualizar resultado"
                    @click="openShowFileModal(item)"
                    v-if="hasLabpacsItem(item)"
                  />
                </div>
              
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </b-table-simple>
  </div>
</template>

<script>

import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'SubLabpacsTable',
  props: {
    exam: Object
  },
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis'),
    ProcedureLine: () => import('@/components/General/ProcedureLine'),
    ChatIcon: () => import('@/assets/icons/chat.svg'),
    ExportIcon: () => import('@/assets/icons/export3.svg'),
    PlusBallonIcon: () => import('@/assets/icons/plus-ballon.svg'),
    PlusBallonGreenIcon: () => import('@/assets/icons/plus-ballon-green.svg'),
    FileIcon: () => import('@/assets/icons/file.svg'),
    PrinterIcon: () => import('@/assets/icons/printer.svg'),
  },
  data() {
    return {
      selected: [],
      showButton: false,
      invalidDate: '-000001-11-30T03:06:28.000000Z',
      groupedItems: null,
      annotation: '',
      clinic: getCurrentClinic(),
      labpacsItem: null,
      patient: null
    }
  },
  mounted() {
    this.groupItems()
  },
  computed: {
  },
  methods: {
    /**
     * Group items by its statuses
     * @returns {Array}
     */
    groupItems() {
      const countExamsStatus = {
        PENDING: 0,
        FINISHED: 0,
        PAUSED: 0
      }
      this.groupedItems = this.exam.appointment_items.reduce((ac, it) => {
        if (it.attendance) {
          ac[it.attendance.id] === undefined
            ? (ac[it.attendance.id] = [it])
            : ac[it.attendance.id].push(it)
          countExamsStatus[it.attendance.status]++
        } else {
          ac.pending === undefined ? (ac.pending = [it]) : ac.pending.push(it)
          countExamsStatus.PENDING++
        }
        return ac
      }, {})

      return this.groupedItems
    },
    parseDate(item) {
      const dateTime = item.attendance
        ? item.attendance.start_datetime
        : this.exam.start_datetime
      return this.moment(dateTime).format('DD/MM/YYYY HH[h]mm')
    },

    isDisabled(procedure) {
      if (this.selected.length === 0) {
        return false
      }

      return this.selected.some(
        item => item.attendance_id !== procedure.attendance_id
      )
    },

    async sendToWorklist(item) {
      const loading = this.$loading.show()
      try {
        const data = {
          patient_id: this.exam.patient.id,
          medical_record_number: this.exam.patient.medical_record_number,
          appointment_item_id: item.id,
          professional_name: this.exam?.professional?.name || '',
          clinic_id: this.clinic.id,
        }
        
        const response = await this.api.sendToWorklist(data)

        if (response.data) {
          this.$toast.success('Exame enviado para a worklist com sucesso')
          this.$emit('reload')
        }

      } catch (error) {
        console.error(error)
        this.$toast.error(error.message)
        throw error
      } finally {
        loading.hide()
      }
    },

    async showFile(item) {
      try {
        const response = await this.api.retrieveFromWorklist(item.labpacs_item.id)

        if (response.data) {
          this.$toast.success('Exame recuperado da worklist com sucesso')
        }

      } catch (error) {
        console.error(error)
        this.$toast.error(error.message)
        throw error
      }
    },

    openAnnotationModal(item) {
      this.$emit('open-annotation-modal', item.labpacs_item)
    },

    openShowFileModal(item) {
      const data = this.setInformations(item)
      this.$emit('open-show-file-modal', data)
    },
    
    closeShowFileModal() {
      this.$bvModal.hide('show-file')
    },

    hasLabpacsItem(item) {
      return item?.labpacs_item
    },
    hasLabpacsItemUrl(item) {
      return item?.labpacs_item?.study_instance_uid
    },
    goToReportRequest() {
      window.location.replace('/laudos-medicos/laudos-pendentes')
    },
    goToApprovedReport(item) {
      // this.$router.push(`/laudo-medico/${medicalReport.patient.id}/${medicalReport.attendance_id}/${medicalReport.id}`);
      window.location.replace(`/laudo-medico/${item.labpacs_item.labpacs_item_reports.patient_id}/${item.labpacs_item.labpacs_item_reports.medical_reports_files.attendance_id}/${item.labpacs_item.labpacs_item_reports.medical_reports_files.id}`)
    },
    setInformations(item) {
      const data = {
        patient: this.exam.patient,
        item_id: item.item_id,
        labpacsItem: item.labpacs_item
      }

      return data
    },
    async handleRequestReport(item) {
      const data = this.setInformations(item)

      this.$emit('request-report', data)
    },
    hasReport(item) {
      return item.labpacs_item?.labpacs_item_reports
    },
    hasRequestedReport(item) {
      return item.labpacs_item?.labpacs_item_reports?.medical_reports_files.status === 'PENDING'
    },
    hasApprovedReport(item) {
      return  item.labpacs_item?.labpacs_item_reports?.medical_reports_files.status === 'FINISHED'
    },
    async handlePrint(item) {
      const loading = this.$loading.show()
      const medicalReport = item.labpacs_item.labpacs_item_reports.medical_reports_files
      await this.createReportDocumentAndPrint(medicalReport)
      loading.hide()
    },
    async createReportDocumentAndPrint(report) {
      const data = {
        attendanceId: report.attendance_id,
        toPrint: [11],
        toSign: [],
        quickPrint: true,
        certContent: null,
        service: null
      }

      try {
        await this.api.finishAttendance(data)
        this.$toast.success('Documentos enviados para impressão')
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

th,
td {
  border: none !important;
  vertical-align: middle !important;
  .blue-text {
    font-weight: 700;
    color: var(--blue-500);
  }
}
.checkbox-td {
  justify-content: center;

  .checkbox {
    margin-bottom: 20px !important;
    vertical-align: middle !important;
    display: flex;
    .disabled {
      background-color: #d9dff2;
      border: none;
      svg {
        display: none;
      }
    }
  }
}

.border-left {
  border-radius: 18px 0px 0px 0px;
}

.border-right {
  border-radius: 0px 18px 0px 0px;
}
.sub-table {
  border: 1px solid var(--neutral-200);
  border-radius: 15px;
  margin: 1rem 0;
}

.sub-table tr {
  &.attendance {
    border: none;
  }
  &.boundary {
    border-bottom: 1px dashed var(--neutral-300);
  }
  &.boundary:last-child {
    border: none;
  }
}

.clipboard-button {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--neutral-300);
  }

  svg {
    stroke: var(--blue-500);
  }
}
.refresh-button {
  padding: 12px 16px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--blue-300);
  }

  svg {
    stroke: var(--blue-500);
    fill: var(--blue-500);
  }
}

.option {
  // margin-top: 10px;
  font-size: 12px;
  border: none;

  &.pending {
    background: #ffebcc;
    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.waiting {
    background: #e2ccff;
    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.finished {
    background: #dbfff0;
    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.canceled {
    background: #f11828;
    p {
      display: inline;
      color: #f6f2ff !important;
    }
  }
}

.icon {

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  gap: 10px;

  width: 32px;
  height: 32px;

  background: #E7ECFE;
  border-radius: 4px;
  
  cursor: pointer;

  &.disabled {
    background: #0000001F;
    cursor: not-allowed;
  }
}

.download-icon {
  fill: var(--blue-500);
  stroke: var(--blue-500);
}

.laudo-icon {
}

.printer-icon {
    fill: none;
    stroke: var(--type-active);
}
</style>
